
import { Component, Vue } from 'vue-property-decorator';
import { LogoutParams, LogoutResponse } from './types';
import { URI_LOGOUT } from '@/api/eob/endpoints';
import { CookieName } from '@/core/cookies';
import { namespace } from 'vuex-class';
import { USER_NOTIFY } from '@/store/list';
import { LogoutStatus } from '@/models/auth';

const AppStore = namespace('AppStore');

@Component({
  name: 'Logout'
})
export default class Login extends Vue {
  created (): void {
    this.logout();
  }

  @AppStore.Action(USER_NOTIFY) notify: any;

  async logout (): Promise<void> {
    try {
      const token = this.$cookies.get(CookieName.USER_TOKEN) || '';
      const logoutParams: LogoutParams = { redirectUri: window.location.origin, token };
      const logoutResponse: LogoutResponse = (await this.$api.post<LogoutResponse>(URI_LOGOUT, logoutParams)).data;
      this.handleSuccessResponse(logoutResponse);
    } catch (e) {
      this.handleErrorResponse(e);
    }
  }

  handleSuccessResponse (logoutResponse: LogoutResponse): void {
    this.$cookies.remove(CookieName.USER_TOKEN);
    this.$cookies.remove(CookieName.USER_HASH);
    if (this.$route.params.error) {
      this.$cookies.set(CookieName.LOGOUT_STATUS, LogoutStatus.SESSION_EXPIRED);
    } else {
      this.$cookies.set(CookieName.LOGOUT_STATUS, LogoutStatus.SUCCESS);
    }
    window.location.href = logoutResponse.redirectUrl;
  }

  handleErrorResponse (e: XMLHttpRequest): void {
    this.$cookies.set(CookieName.LOGOUT_STATUS, LogoutStatus.ERROR);
    this.notify({ msg: e, type: 'error', permanent: true });
  }
}
